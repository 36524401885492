import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Hero } from './sections/hero/_index'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const Scr = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
      </Layout>
    </Wrapper>
  )
}

export default Scr
